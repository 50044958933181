import { Box, Button, Stack, Typography } from '@mui/material';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import Confetti from 'react-confetti';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import LastWillDoc from '#/components/pages/LastWill/steps/content/YourLastWill/LastWillDoc';
import { LastWillValues } from '#/types/lastWill';
import useAuth from '#/hooks/useAuth';
import { useGetBeneficiaries } from '#/api/beneficiaries';
import { useGetRepresentative } from '#/api/representativeQueries';
import { useGetGoods, useSubmitDocument } from '#/api/servicesQueries';
import { useUpdateProfile } from '#/api/userQueries';
import useHighlightWords from '#/hooks/useHighlightWords';
import { useGetChildren } from '#/api/childrenQueries';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import UploadSignedDoc from '#/components/shared/UploadSignedDoc';
import {
  LinkToSettings,
  NoteriseByLawyer,
} from '#/components/pages/PowerAttorney/steps/content/YourPowerOfAttorney/highlight-formats';
import AdvisorsListView from '#/components/shared/advisor-helpers/partner/advisors-list-view';
import NonPartnerView from '#/components/shared/advisor-helpers/non-partner/non-partner-view';
import ModalWrapper from '#/components/shared/advisor-helpers/modal-wrapper';

type Props = {
  outputValues?: LastWillValues | any;
};
const YourLastWill = ({ outputValues }: Props) => {
  const [openAdvisorModal, setOpenAdvisorModal] = useState(false);

  const { user, refetch } = useAuth();
  const { currentAccount } = useAccountSwitch();
  const { translate } = useLocales();
  const { state } = useLocation();
  const highlightWords = useHighlightWords();
  const navigate = useNavigate();

  const { data: children } = useGetChildren();
  // const { handleDisableDownloads } = useDisableDownloads('last_will');
  const { mutateAsync: submitDocument, isLoading: submitDoc } =
    useSubmitDocument('last-will');

  const { mutateAsync: updateProfile } = useUpdateProfile();

  const handleOpenAdvisorModal = () => setOpenAdvisorModal(true);
  const handleCloseAdvisorModal = () => setOpenAdvisorModal(false);

  const handleContinueProcess = () => {
    setOpenAdvisorModal(false);
    const targetURL =
      'https://app.getyourlawyer.ch/gateway/consultancy?utm_source=gutgeregelt.ch&utm_medium=referral&utm_campaign=may2024';
    window.open(targetURL, '_blank');
    navigate('/dashboard/home');
  };

  // const translatedObligations = Array.from({ length: 2 }, (_, i) =>
  //   translate(`lastWill.stepFour.obligation${i + 1}`)
  // );

  const translatedNextSteps = Array.from({ length: 3 }, (_, i) =>
    translate(`lastWill.stepFour.nextStep${i + 1}`)
  );

  const { data: beneficiaries } = useGetBeneficiaries();
  const { data: willExecutor } = useGetRepresentative(
    outputValues?.will_executor,
    !!outputValues?.will_executor
  );
  const { data: willExecutor2 } = useGetRepresentative(
    outputValues?.will_executor_2,
    !!outputValues?.will_executor_2
  );
  const { data: assets } = useGetGoods('last-will');

  const institutionBeneficiaries = beneficiaries?.filter(
    (beneficiary: any) => beneficiary?.list_of_institutions
  );

  const isPartner = user?.partner;

  const generatePDFDocument = async () => {
    const blob = await pdf(
      <LastWillDoc
        outputValues={{
          ...outputValues,
          children,
          beneficiaries,
          assets,
          willExecutor,
          willExecutor2,
          user: currentAccount,
          institutionBeneficiaries,
        }}
      />
    ).toBlob();

    const formData = new FormData();
    formData.append(
      'document',
      blob,
      `${String(
        translate('global.services.lastWill')
      )}_${currentAccount?.first_name}_${currentAccount?.last_name}_${new Date()
        .toLocaleDateString('en-GB')
        .split('/')
        .join('-')}.pdf`
    );

    await submitDocument(formData)
      .then(() => {
        saveAs(
          blob,
          `${String(
            translate('global.services.lastWill')
          )}_${currentAccount?.first_name}_${currentAccount?.last_name}_${new Date()
            .toLocaleDateString('en-GB')
            .split('/')
            .join('-')}.pdf`
        );
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.document_submit'))
        );
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updateProfile({
      // eslint-disable-next-line no-unsafe-optional-chaining
      downloaded: ['last_will'],
    }).then(() => refetch());
  };

  const hideConfetti =
    user?.downloaded?.includes('last_will') || state?.markAsDone;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {!hideConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={400}
          style={{ width: '100%' }}
        />
      )}
      <Stack
        spacing={2}
        sx={{
          marginBottom: 5,

          fontSize: '1rem',
        }}
      >
        <Typography>
          {String(translate('lastWill.stepFour.introParagraph'))}
        </Typography>
        <Typography fontWeight="500">
          {String(translate('lastWill.stepFour.helperParagraph'))}
        </Typography>
        {/* {translatedObligations.map((obligation, i) => ( */}
        {/*  <Stack */}
        {/*    direction="row" */}
        {/*    alignItems="center" */}
        {/*    spacing={2} */}
        {/*    key={`key-obligation${i}`} */}
        {/*  > */}
        {/*    <Iconify icon="tabler:point" /> */}
        {/*    <Typography>{String(obligation)}</Typography> */}
        {/*  </Stack> */}
        {/* ))} */}

        {translatedNextSteps.map((nextStep, i) => (
          <Stack
            direction="row"
            alignItems="center"
            key={`key-step${i}`}
            spacing={1}
          >
            <Typography>{i + 1}.</Typography>
            <Typography gutterBottom>
              {i === 0 && (
                <NoteriseByLawyer
                  context={String(nextStep)}
                  targets={[
                    String(
                      translate(
                        'lastWill.stepFour.highlightedText.noterizeWithLawyer'
                      )
                    ),
                    String(
                      translate(
                        'lastWill.stepFour.highlightedText.linkToSettings'
                      )
                    ),
                  ]}
                  onClick={handleOpenAdvisorModal}
                />
              )}
              {i === 1 && (
                <LinkToSettings
                  context={String(nextStep)}
                  targets={['hier klicken']}
                />
              )}
              {i === 2 && String(nextStep)}
            </Typography>
          </Stack>
        ))}
        {/* <Typography> */}
        {/*  {highlightWords( */}
        {/*    String(translate('lastWill.stepFour.helperParagraph2')), */}
        {/*    ['https://www.ztr.ch/notariatsinfo/'], */}
        {/*    'https://www.ztr.ch/notariatsinfo/' */}
        {/*  )} */}
        {/* </Typography> */}
        <Typography>
          {highlightWords(
            String(translate('lastWill.stepFour.helperParagraph3')),
            [String(translate('lastWill.stepFour.contactForm'))],
            'kontakt'
          )}
        </Typography>
        <UploadSignedDoc
          serviceName="last-will"
          documentType="lastWill"
          serviceInvalidateKey="last-will/get"
        />
      </Stack>
      {!state?.markAsDone && (
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '50%',
            },
            margin: '0 auto',
          }}
        >
          {/* {handleDisableDownloads() ? (
            <BlurryPurchaseCover
              service="lastWill.stepFour.document"
              user={user}
            />
          ) : ( */}
          <PDFViewer
            style={{
              width: '100%',
              height: '550px',
            }}
            showToolbar={false}
          >
            <LastWillDoc
              outputValues={{
                ...outputValues,
                children,
                assets,
                beneficiaries,
                willExecutor,
                willExecutor2,
                user,
                institutionBeneficiaries,
              }}
            />
          </PDFViewer>
          {/* )} */}
          <Button
            disabled={submitDoc}
            variant="contained"
            color="primary"
            sx={{ width: 1, mt: 2 }}
            onClick={generatePDFDocument}
          >
            <Typography>{String(translate('global.download'))}</Typography>
          </Button>
        </Box>
      )}
      <ModalWrapper
        open={openAdvisorModal}
        onClose={handleCloseAdvisorModal}
        modalTitle="global.legalAdvisorsModal.title"
        modalDescription={
          isPartner ? '' : `global.legalAdvisorsModal.description`
        }
      >
        {isPartner ? (
          <AdvisorsListView
            advisorType="legal"
            closeModal={handleCloseAdvisorModal}
          />
        ) : (
          <NonPartnerView
            advisorImage="/assets/images/getyourlawyer_logo.svg"
            isComplexForm={false}
            handleContinueProcess={handleContinueProcess}
            isFinancialAdvisor={false}
          />
        )}
      </ModalWrapper>
    </Box>
  );
};

export default YourLastWill;
